










import { Component, Vue } from 'vue-property-decorator';
import { UnbreakableSpace } from '@projetlucie/lc-front-sdk-old';
import { DocumentServices } from '@/services';
import { toast } from '@/store';

@Component
export default class MandatSEPA extends Vue {
  async created() {
    await this.downloadSEPA();
  }

  get unbreakableSpace() {
    return UnbreakableSpace;
  }

  public async downloadSEPA() {
    const numMandat = this.$route.query.numeroMandat as string;
    if (numMandat) {
      return await DocumentServices.downloadSEPA(numMandat);
    }

    toast.pushError('Impossible de telecharger le document, le numero de mandat est incorrect.');
  }
}
